import { apiClient } from "./apiClient";
import { saveAs } from "file-saver";
import apiUltils from "./apiUltils";
import { useEffect, useState } from "react";

export default {
  delete(id) {
    return apiClient.delete(`/rest/org/${id}`);
  },

  permanentlyDelete(id) {
    return apiClient.delete(`/rest/org/${id}/permanentlyDelete`);
  },

  getBasicOrgWithId(id) {
    return apiClient.get(`/rest/org/basicOrg/${id}`);
  },

  isAllowConfigARR(id) {
    return apiClient.get(`/rest/org/isAllowConfigARR/${id}`);
  },

  getByPartner(id) {
    var uri = "/rest/org/partner";
    if (id) {
      uri += "?id=" + id;
    }
    return apiClient.get(uri);
  },

  getBasicInfo() {
    return apiClient.get(`/rest/org/basicOrganisation`);
  },

  getOrganizationAttributeTypeOptions(organizationAttributeType) {
    return apiClient.get(
      `/rest/org/organizationAttributeTypeOptions?organizationAttributeType=${organizationAttributeType}`
    );
  },

  findOne(params) {
    return apiClient.get("/rest/org/" + params.id);
  },

  search(params) {
    return apiClient.post(`/rest/org/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  updateInvoiceSettings(id, params) {
    return apiClient.put(
      `/rest/org/invoiceSettings/${id}`,
      JSON.stringify(params),
      {
        headers: { "content-type": "application/json" },
      }
    );
  },

  uploadWifiResolutionFile(id, params) {
    return apiClient.post(
      "/rest/org/wifi/upload/wifiResolutionFile/" + id,
      params,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
  },

  uploadBlockedWifiResolutionFile(id, params) {
    return apiClient.post(
      "/rest/org/wifi/upload/blockedWifiResolutionFile/" + id,
      params,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
  },

  getResolutionInfo(id) {
    return apiClient.get("/rest/org/wifi/getResolutionInfo/" + id);
  },

  wifiResolutionFile(id) {
    return apiClient
      .get("/rest/org/wifi/download/wifiResolutionFile/" + id, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Wifi_resolution_${id}.xlsx`);
      });
  },

  wifiUnresolutionFile(id) {
    return apiClient
      .get("/rest/org/wifi/download/wifiUnresolvedFile/" + id, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Wifi_unresolved_${id}.xlsx`);
      });
  },

  getBlockedResolutionInfo(id) {
    return apiClient.get("/rest/org/wifi/blockedResolutionInfo/" + id);
  },

  downloadBlockedWifiResolutionFile(id) {
    return apiClient
      .get("/rest/org/wifi/download/blockedWifiResolutionFile/" + id, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Blocked_wifi_resolution_${id}.xlsx`);
      });
  },

  downloadTemplateFile() {
    return apiClient
      .get("/rest/org/wifi/downloadTemplateFile", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Template_example.xlsx");
      });
  },

  downloadBlockedTemplateFile() {
    return apiClient
      .get("/rest/org/wifi/downloadBlockedTemplateFile", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Blocked_template_example.xlsx");
      });
  },

  getPrivateMACAddresses(orgId) {
    return apiClient.get("/rest/org/wifi/privateMacAddresses/" + orgId);
  },

  getUnresolvedMACAddresses(orgId) {
    return apiClient.get("/rest/org/wifi/unresolvedMacAddresses/" + orgId);
  },

  getBlockedMacAddresses(orgId) {
    return apiClient.get("/rest/org/wifi/blockedMacAddresses/" + orgId);
  },

  deletePrivateMACAddress(orgId, id) {
    return apiClient.delete(`/rest/org/wifi/deletePrivate/${orgId}/${id}`);
  },

  deleteUnresolvedMACAddress(orgId, id) {
    return apiClient.delete(`/rest/org/wifi/deleteUnresolved/${orgId}/${id}`);
  },

  deleteBlockedMACAddress(orgId, id) {
    return apiClient.delete(`/rest/org/wifi/deleteBlocked/${orgId}/${id}`);
  },

  downloadVerificationFile(orgId) {
    return apiClient
      .get("/rest/org/domainVerification/downloadFile/" + orgId, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        saveAs(blob, "sensolus-domain-verification-template.html");
      });
  },

  testResolution(id, params) {
    return apiClient.post("/rest/org/wifi/testResolution/" + id, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  updateSSO(id, params) {
    return apiClient.put(`/rest/org/${id}/sso`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  verifyDomain(id, domainName) {
    return apiClient.post(
      `/rest/org/domainVerification/verify/${id}`,
      domainName,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  forceVerifyDomain(id, domainName) {
    return apiClient.post(
      `/rest/org/domainVerification/forceVerify/${id}`,
      domainName,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getOrgTagByOrg(orgId) {
    return apiClient.get(`/rest/org_tag/getByOrg?orgId=${orgId}`);
  },

  update(id, params) {
    return apiClient.put(`/rest/org/${id}`, JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  savePasswordRule(orgId, params) {
    return apiClient.put(
      `/rest/org/passwordRules/${orgId}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  statistics(params) {
    return apiClient.get(
      apiUltils.buildParamsListURI(`/rest/org/statistics`, params)
    );
  },

  create(params) {
    return apiClient.post(`/rest/org/`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getLayers() {
    return apiClient.get(`/rest/org/layers`);
  },

  checkPagePermission(params) {
    return apiClient.get(`/rest/org/checkPermission`, { params: params });
  },

  updateBeaconSettings(params) {
    return apiClient.put(
      `/rest/org/updateBeaconSettings?orgId=${params.orgId}`,
      params.beaconSettings,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getDefaultConfiguration(orgId) {
    return apiClient.get(
      `/rest/org/getDefaultConfiguration?orgId=${orgId ? orgId : ""}`
    );
  },

  resetAllUnresolvedMacAddresses(orgId) {
    return apiClient.delete(`/rest/org/wifi/resetAll/${orgId}`);
  },

  countOrgsByFilters(params) {
    return apiClient.post("/rest/org/filter/count", JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getOrgByName(orgNames) {
    return apiClient.post(`/rest/org/basicOrganisation/byName`, orgNames, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};

export const useGetOrg = () => {
  const [orgs, setOrgs] = useState([]);
  useEffect(() => {
    apiClient.get(`/rest/org/basicOrganisation`).then(({ data }) => {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        list.push({
          value: data[i].id,
          label: data[i].name,
        });
      }
      setOrgs(list);
    });
  }, []);
  return orgs;
};
