import { useEffect, useState } from "react";
import { apiClient } from "./apiClient";

export default {
  getLanguage(data) {
    return apiClient.get(`/rest/language/i18n?locale=${data}`);
  },

  getOriginalLanguage(data) {
    return apiClient.get(`/rest/language/i18n/original?locale=${data}`);
  },

  getRedirectExternalIdP(orgId, redirectTo) {
    return apiClient.get(`/rest/users/redirect/externalIdP/${orgId}`, {
      params: { redirectTo: redirectTo },
    });
  },

  impersonateUser(id) {
    return apiClient.put(`/rest/users/impersonate/${id}`);
  },

  impersonateAdminSupportUserByOrgId(orgId, prevToken) {
    return apiClient.put(
      `/rest/users/impersonate/admin/organisation/${orgId}`,
      prevToken
    );
  },

  impersonateViewerSupportUserByOrgId(orgId) {
    return apiClient.put(
      `/rest/users/impersonate/viewer/organisation/${orgId}`
    );
  },

  getPrevUserByTokens(token, csrf) {
    return apiClient.put(`/rest/users/token`, { token: token, csrf: csrf });
  },

  getDataByPage(params) {
    return apiClient.get("/rest/users", { params: params });
  },

  delete(id) {
    return apiClient.delete(`/rest/users/${id}`);
  },

  get(id) {
    return apiClient.get(`/rest/users/${id}`);
  },
  requestForAnVerifiedEmail(id) {
    return apiClient.get(`/rest/users/requestForAnVerificationEmail/${id}`);
  },
  forceVerifyEmailAddress(id) {
    return apiClient.get(`/rest/users/forceVerifyEmailAddress/${id}`);
  },
  create(orgId, params) {
    return apiClient.post(`/rest/users/?orgId=${orgId}`, params);
  },
  update(userId, params) {
    return apiClient.put(`/rest/users/${userId}`, params);
  },
  changeImageUser(id, nameFile) {
    return apiClient.put(`/rest/users/changeImage/${id}/?image=${nameFile}`);
  },
  updateUserProfile(userId, params) {
    return apiClient.put(`/rest/users/profile/${userId}`, params);
  },
  changePassword(userID, params) {
    return apiClient.post(`/rest/users/changePassword/${userID}`, params);
  },
  changeMyPassword(userID, params) {
    return apiClient.post(`/rest/users/changeMyPassword/${userID}`, params);
  },
  register(userId, params) {
    return apiClient.put(`/rest/users/register/${userId}`, params);
  },

  verifyEmailCode(codeText) {
    return apiClient.get(`/rest/users/verifyEmailCode?codeText=${codeText}`);
  },

  search(params) {
    return apiClient.post(`/rest/users/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getByOrg(orgId) {
    return apiClient.get(`/rest/users/getByOrg?orgId=${orgId}`);
  },

  getAll() {
    return apiClient.get("/rest/users");
  },

  countUsersByFilters(params) {
    return apiClient.post("/rest/users/filter/count", JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getContactsEmailByOrg(orgId) {
    let uri = "/rest/users/getContactsByOrg";
    if (orgId) {
      uri += "?orgId=" + orgId;
    }
    return apiClient.get(uri);
  },
  invite(orgId, params) {
    return apiClient.post(`/rest/users/invite?orgId=${orgId}`, params);
  },
  getInvitedUserInfo(code) {
    return apiClient.get(`/rest/users/invite/${code}`);
  },
  activateInvitation(params) {
    return apiClient.post(`/rest/users/invite/activate`, params);
  },
  resendInvitation(id) {
    return apiClient.post(`/rest/users/resendInvite/${id}`);
  },
  debounce(id) {
    return apiClient.put(`/rest/users/debounce/${id}`);
  },
  checkUserNameAvailability(userName) {
    return apiClient.get(
      `/rest/users/check-username-availability?userName=${userName}`
    );
  },
};

export const useGetUser = (orgId) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    apiClient.get(`/rest/users/getByOrg?orgId=${orgId}`).then(({ data }) => {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        list.push({
          value: data[i].id,
          label: data[i].fullName,
        });
      }
      setUsers(list);
    });
  }, [orgId]);
  return users;
};
