import { Alert } from "react-bootstrap";
import IcomoonIcon from "../Icons/IcomoonIcon";

const SntInfoPanel = ({ className, children, maxWidth = 900, customStyle }) => {
  return (
    <Alert
      className={className}
      variant="sensolus-grey"
      style={{
        backgroundColor: "#eff3f4",
        border: "none",
        color: "#304269",
        ...customStyle,
      }}
    >
      <div className="d-flex">
        <div style={{ width: 24 }}>
          <IcomoonIcon className="me-2" icon="info" size={16} />
        </div>
        {/* text longer than 900px is bad for reading. */}
        <div style={{ maxWidth: maxWidth > 0 ? maxWidth : null }}>
          {children}
        </div>
      </div>
    </Alert>
  );
};

export default SntInfoPanel;
